import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from '../Styles/GlobalStyles';
import Theme from '../Styles/Theme';

import './Real/css/style.scss';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

const App = () => {
  const Main = React.lazy(() => import('./Real/fullReal'));
  const Join = React.lazy(() => import('./join/Main_join'));
  const Boucher = React.lazy(() => import('./Real/L12_sub_boucher'));
  const appCummunity = React.lazy(() => import('./appCummunity'));
  const Price = React.lazy(() => import('./Real/L11_sub_price'));
  const FAQ = React.lazy(() => import('./Real/L14_sub_FAQ'));
  const Custom = React.lazy(() => import('./Real/L13_sub_custom'));

  // console.log = () => {};

  return (
    <ThemeProvider theme={Theme}>
      <Suspense fallback={<div></div>}>
        <GlobalStyles />
        <Wrapper>
          <Switch>
            {/* 라우터 추가 시 ./Routes.js에 추가 후 빌드 필요 (구글 크롤링 시 사이트맵 제공) */}
            <Route path="/" component={Main} exact />
            <Route path="/join" component={Join} />
            <Route path="/price" component={Price} />
            <Route path="/boucher" component={Boucher} />
            <Route path="/appCummunity" component={appCummunity} />
            <Route path="/FAQ" component={FAQ} />
            <Route path="/custom" component={Custom} />
          </Switch>
        </Wrapper>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
